exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "._3Y6twLXZYjw_JLpYZM8OVR {\n  margin: 50px 0px;\n  font-size: 16px;\n}\n._3Y6twLXZYjw_JLpYZM8OVR div {\n  margin: 20px 0px;\n}\n._3Y6twLXZYjw_JLpYZM8OVR label {\n  display: inline-block;\n  font-weight: bold;\n  margin-right: 10px;\n  width: 120px;\n  text-transform: uppercase;\n}\n._3Y6twLXZYjw_JLpYZM8OVR input {\n  padding: 5px 10px;\n  width: 200px;\n}\n._3Y6twLXZYjw_JLpYZM8OVR button {\n  margin-top: 40px;\n}\n\n", "", {"version":3,"sources":["/codebuild/output/src1008731445/src/app_admin_redux/src/styles/login.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,gBAAgB;CACjB;AACD;EACE,iBAAiB;CAClB;AACD;EACE,sBAAsB;EACtB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,0BAA0B;CAC3B;AACD;EACE,kBAAkB;EAClB,aAAa;CACd;AACD;EACE,iBAAiB;CAClB","file":"login.css","sourcesContent":[".loginWrapper {\n  margin: 50px 0px;\n  font-size: 16px;\n}\n.loginWrapper div {\n  margin: 20px 0px;\n}\n.loginWrapper label {\n  display: inline-block;\n  font-weight: bold;\n  margin-right: 10px;\n  width: 120px;\n  text-transform: uppercase;\n}\n.loginWrapper input {\n  padding: 5px 10px;\n  width: 200px;\n}\n.loginWrapper button {\n  margin-top: 40px;\n}\n\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"loginWrapper": "_3Y6twLXZYjw_JLpYZM8OVR"
};